:root {
  --gradient-gold-light: #fceda3;
  --gradient-gold-dark: #fe9666;
  --gradient-purple-light: #8154ff;
  --gradient-purple-dark: #512bbd;
  --gradient-green-light: #19e18d;
  --gradient-green-dark: #0cbf74;
  --gradient-blue-light: #0aa6ff;
  --gradient-blue-dark: #1972a4;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @variant hover {
    a {
      @apply opacity-50;
    }
  }
}

@layer utilities {
  .main-container {
    @apply mx-auto px-4;
    max-width: theme("screens.2xl");
  }

  .item-image-container {
    border: 3px solid theme("colors.gray.50");
    transition: border 0.3s ease-in-out;
  }

  .item-gradient-gray {
    background: linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%);
    @apply border border-gray-200;
  }

  .item-gradient-gray img {
    filter: grayscale(100%);
  }

  .item-gradient-3 {
    background: linear-gradient(
      360deg,
      var(--gradient-gold-dark) 0%,
      var(--gradient-gold-light) 100%
    );
    background-color: var(--gradient-gold-dark);
  }

  .item-image-container-hover.item-gradient-3:hover {
    border-color: var(--gradient-gold-dark);
  }

  .item-gradient-2 {
    background: linear-gradient(
      180deg,
      var(--gradient-purple-light) 0%,
      var(--gradient-purple-dark) 100%
    );
    background-color: var(--gradient-purple-dark);
  }

  .item-image-container-hover.item-gradient-2:hover {
    border-color: var(--gradient-purple-dark);
  }

  .item-gradient-1 {
    background: linear-gradient(
      180deg,
      var(--gradient-green-light) 0%,
      var(--gradient-green-dark) 100%
    );
    background-color: var(--gradient-green-dark);
  }

  .item-image-container-hover.item-gradient-1:hover {
    border-color: var(--gradient-green-dark);
  }

  .item-gradient-0 {
    background: linear-gradient(
      180deg,
      var(--gradient-blue-light) 0%,
      var(--gradient-blue-dark) 100%
    );
    background-color: var(--gradient-blue-dark);
  }

  .item-image-container-hover.item-gradient-0:hover {
    border-color: var(--gradient-blue-dark);
  }

  @responsive {
    .latest-store-list-items {
      margin-left: calc((100vw - theme("screens.2xl")) / 2);
    }
  }

  input[type="number"].text-right::-webkit-outer-spin-button,
  input[type="number"].text-right::-webkit-inner-spin-button {
    display: none;
  }
}
